.form-box{
    border-radius: 15px;
    position: relative;
    min-height: 300px;

    .invalid input, .invalid textarea {
        border: 2px solid red;
    }

    .read-more{
        cursor: pointer;
    }

    .errtxt{
        display: none;
        position: absolute;
        bottom: -22px;
        left: 17px;
        z-index: 999;
        color: red;
        font-weight: bold;
    }

    .agreement-box.invalid{
       .errtxt{
           display: inline-block !important;
       }
    }

    .modal-exit-btn{
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 20;
        cursor: pointer;
        width: 20px;
        height: 20px;
    }

    input, textarea{
        width: 100%;
        border: 1px solid #ccbdd2;
        border-radius: 10px;
        padding: 5px 10px 5px 20px;
        margin: 3px 0 20px 0;
        outline: none;
    }

    label{
        margin-bottom: 0;
        color: #653877;
        font-weight: 400;
        font-size: 15px;
    }

    .agreement-box input{
        width: unset;
        padding: 0;
        margin: 0;
        margin: 5px 5px 0 0;
    }

    .form-btn{
        outline: none; 
        border: none;
    }
}