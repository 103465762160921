
$color-text: #000;
$color-blue: #1C92ED;
$color-orange: #FC601D;
$silver: #848484;

// portfolio-color
$white:#ffffff;
$hapi-color: #65b5d2;

$light: 'Titillium Light', arial; 
$thin: 'Titillium Thin', arial;
$bold: 'Titillium Web', sans-serif;

.mt-6{
    margin-top: 5rem;
}

// $grid-breakpoints: (
// 		xs: 0,
// 		sm: 576px,
// 		md: 768px,
// 		lg: 992px,
// 		xl: 1200px,
// 		xxl: 1900px
// );

// $container-max-widths: (
// 		sm: 540px,
// 		md: 720px,
// 		lg: 960px,
// 		xl: 1140px,
// 		xxl: 1610px
// );

  

// *{
//     box-shadow: 0px 0px 0px 1px rgba(red, .2);
// }
// .container{
//     box-shadow: 0px 0px 0px 1px rgba(green, .4);
// }
// .row{
//     box-shadow: 0px 0px 0px 1px rgba(blue, .2);
// }
