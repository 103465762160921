header{
    background: #f8f8f8;
    transition: 1s background;
    position: relative;

    .mobile-logo{
        display: none;

        @media(max-width: 992px){
            display: flex;
        }
    }

    .brief-button{
        position: relative;

        &_ribbon{
            position: absolute;
            top: 50%;
            left: -140px;
            color: #000;
            font-size: 16px;
            transform: translateY(-50%);
            text-decoration: none;

            @media(max-width: 992px){
                display: none;
            }
        }
        @media(max-width: 992px){
            display: none;
        }
    }

    .contact-us{
        height: 75px;
    }
}

.header-kv_box{
    position: relative;
}

.header-content{
    position: relative;
    min-height: 570px;
    padding-bottom: 50px;

    @media(max-width: 992px){
        height: 100%;
        overflow: hidden;
        min-height: unset;
    }

    @media(max-height: 770px){
        height: 100%;
        min-height: unset;
    }

    &:after{
        content:'';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 0;
        border-bottom: 150px solid #fff;
        border-left: 100px solid transparent;

        @media(max-width: 600px){
            display: none;
        }
    }


    .menu-container{

        @media(max-width: 992px){
            position: fixed;
            top: 0;
            left: 0;
            background: #f8f8f8;
            width: 200px !important;
            z-index: 100;
            height: 100%;
            max-width: unset;
            transform: translateX(-100%);
            transition: .2s all;
        }

        .menu-logo{
            margin-top: -20px;

            @media(max-width: 992px){
                margin-top: 30px;
            }
        }

        .nav_menu{
            list-style: none;

            @media(max-width: 992px){
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }

            li{
                text-transform: uppercase;
                margin: 30px 0;
                font-weight: 300;
                font-size: 16px;
                color: #696969;
                cursor: pointer;
                transition: .1s all;

                @media(max-width: 992px){
                    transform: translateX(-1000px);
                    transition: .1s all;
                }

                @media(max-width: 768px){
                    margin: 15px 0;
                }
            }

            .active-menu{
                font-weight: 700;
                padding-left: 20px;
                position: relative;

                &:before{
                    content: '/';
                    position: absolute;
                    top:50%;
                    left: 0px;
                    transform: translateY(-50%);
                }
            }
        }
    }

    .courtine{
        display: none;

        @media(max-width: 992px){
            position: fixed;
            top:0;
            left: 0;
            width: 100%;
            height: 100vh;
            background: rgba(#000, .5);
            z-index: 90;
        }
    }

    .socials_list{
        list-style: none;
    
        li{
            cursor: pointer;
    
            a{
                height: 20px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
            }
            
        }
    }

    .title-box{
        margin-top: 100px;
        
        @media(max-width: 768px){
           margin: 0;
           padding: 20px 25px;
        }

        .header_title{
            font-size: 45px;
            color: #848484;
            font-weight: 600;
    
            @media(max-width: 1400px){
                font-size: 40px;
            }

            @media(max-width: 992px){
                font-size: 35px;
            }

            @media(max-width: 600px){
                font-size: 28px;
            }
        }
    
        .header_subtitle{
            font-size: 35px;
            color: #56256a;
            font-weight: 200;
    
            @media(max-width: 1400px){
                font-size: 30px;
            }

            @media(max-width: 992px){
                font-size: 28px;
                padding-right: 50px;
            }

            @media(max-width: 600px){
                font-size: 21px;
            }

        }
    
        .header_button{
            font-size: 20px;
            color: #f38f1a;
            font-weight: 500;
            display: flex;
            flex-direction: row;
    
            &:hover{
                text-decoration: none;

                .header-arrow-right{
                    transform: translateX(10px);
                    transition: .5s all;
                }
            }

            .header-arrow-right{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                transition: .5s all;
            }
        }
    }
    
}

.slider-buttons{

    p{
        color:#fff;
        font-weight: 300;
        margin: 0;
    }
}

.scroll-down-icon{
    background: url('../src/img/arrow-down-gray.svg')no-repeat;
    width: 40px;
    height: 32px;
    transition: .5s all;
}

.scroll-down{
    font-weight: 300;
    color: #696969;
    position: absolute;
    top: 40%;
    right: -40px;
    transform: rotate(-90deg);
    cursor: pointer;
    z-index: 9;

    &:hover{
        i{
            transform: translateX(-10px);
            transition: .5s transform;
        }
    }

    @media(max-width: 1200px){
        display: none !important;
    }
}

.slide-header-theme2{
    header{
        transition: 1s background;
        background:  url('../src/img/bg-slide2.png');
        background-size: cover;
        background-position: center;
    }

    .header_title{
        transition: .5s all;
        color: #fff !important;
    }

    .header_subtitle{
        transition: .5s all;
        color: #f38f1a !important;
    }

    .nav_menu li{
        transition: .5s all;
        color: #fff !important;
    }

    .scroll-down{
        color: #fff;
    }

    .nav-hamburger{
        span:after{
            width: 35px;
            background: #fff !important; 
        }
    }

    .brief-button{
        &_ribbon{
            color: #fff;
        }
    }

    .scroll-down-icon{
        background: url('../src/img/arrow-down.svg')no-repeat;
        width: 40px;
        height: 32px;
        transition: .5s all;
    }

    #navigation{
        .slick-dots button{
            &:before{
                color: #fff;
            }
        }
        #next{
            background: url('../src/img/arrow-down.svg')no-repeat;
        }
        #prev{
            background: url('../src/img/arrow-down.svg')no-repeat;
        }
    }
}

.slide-header-theme3{
    header{
        transition: 1s background;
        background:  url('../src/img/bg-slide3.png');
        background-size: cover;
        background-position: center;
    }

    .header_title{
        transition: .5s all;
        color: #fff !important;
    }

    .header_subtitle{
        transition: .5s all;
        color: #fff !important;
    }

    .nav_menu li{
        transition: .5s all;
        color: #fff !important;
    }

    .scroll-down{
        color: #fff;
    }

    .nav-hamburger{
        span:after{
            width: 35px;
            background: #fff !important; 
        }
    }

    .brief-button{
        &_ribbon{
            color: #fff;
        }
    }

    .scroll-down-icon{
        background: url('../src/img/arrow-down.svg')no-repeat;
        width: 40px;
        height: 32px;
        transition: .5s all;
    }

    #navigation{
        .slick-dots button{
            &:before{
                color: #fff;
            }
        }
        #next{
            background: url('../src/img/arrow-down.svg')no-repeat;
        }
        #prev{
            background: url('../src/img/arrow-down.svg')no-repeat;
        }
    }
}

