body{
    font-family: 'Titillium Web', sans-serif;
}

.ml-7{
    margin-left: 7rem !important;
}

.modal{
    padding: 0 !important;
}

.custom-paddings{
    padding: 0 50px;

    @media(max-width: 1440px){
        padding: 0 80px 0 30px;
    }

    @media(max-width: 768px){
        padding: 0;
    }
}

.signature{
    font-size: 17px;
    font-weight: 300;
    color: #696969;
    text-transform: uppercase;
}

.section-title{
    position: relative;
    text-align: center;
    margin: 150px 0 100px 0;
    color: #696969;
    font-size: 40px;
    z-index: 1;
    display: inline-block;
    
    @media(max-width: 768px){
        margin: 75px 0 50px 0;
    }

    &:before{
        content: '';
        position: absolute;
        top: -86%;
        left: -127px;
        background: url('../src/img/sectiontitle-icon.png')no-repeat;
        width: 190px;
        height: 195px;
    }
}

.slick-slide{
    outline: none !important;

    &:focus{
        outline: none !important;
    }
}

#navigation{
    @media(max-height: 770px){
        margin-top: -20px !important;
    }

    @media(max-height: 680px){
        margin-top: -45px !important;
    }

    .slick-dots{
        position: relative;
        display: inline-block;
        width: auto;
        bottom: unset;
    }

    #prev{
        position: absolute;
        top: 0;
        left: 40%;
        z-index: 99;
        background: url('../src/img/arrow-down-black.svg')no-repeat;
        width: 40px;
        height: 32px;
        cursor: pointer;

        @media(max-width: 992px){
            left: 30%;
        }

        @media(max-width: 580px){
            left: 20%;
        }
    }

    #next{
        position: absolute;
        top: 0;
        right: 40%;
        z-index: 99;
        background: url('../src/img/arrow-down-black.svg')no-repeat;
        width: 40px;
        height: 32px;
        transform-origin: 50%;
        transform: rotate(180deg);
        cursor: pointer;

        @media(max-width: 992px){
            right: 30%;
        }

        @media(max-width: 580px){
            right: 20%;
        }
    }
}

.about-slider{

    .slick-prev, .slick-next {
        background: #000;
    }

    .single-slide{
        &:focus{
            outline: none;
        }

        &_content{
            font-weight: 300;
            font-style: italic;
            font-size: 18px;
            color: #696969;
            position: relative;

            &:before{
                content:'';
                position: absolute;
                top: -30px;
                left: 0;
                background:url('../src/img/about-company-icon.png')no-repeat;
                width: 67px;
                height: 56px;
            }
        }
    }

}