.portfolio{
    position:relative;
    overflow: hidden;

    &:before{
        content: '';
        position: absolute;
        top: 20%;
        left: 0;
        width: 698px;
        height: 772px;
        background: url('../src/img/watermark1.png')no-repeat;

        @media(max-width: 1200px){
            display: none !important;
        }

    }
}

.clients{
   padding-bottom: 30px;

    &_subtitle{
        font-size: 37px;
        font-weight: 200;
        color: #696969;
    }

    &_box{
        margin: 40px 0 20px 0;;

        &__single{
            height: 50px;
            display: flex;
        }
    }
}

.motto{
    background: #f8f8f8;
    position: relative;

    .motto-img{
        @media(max-width: 768px){
            width: 60%;
        }
    }

    &:after{
        content:'';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-top: 150px solid #fff;
        border-right: 150px solid transparent;
    }

    &_content{
        color: #696969;
        font-weight: 300;
        font-size: 28px;
        font-style: italic;

        @media(max-width: 1200px){
            font-size: 22px;
        }
    }

}

.meet-us{
    max-width: 100%;
    min-height: 800px;
    background-image: url('../src/img/meetus.png');
    background-color: #f8f8f8;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    position: relative;
    overflow-x: hidden;

    @media(max-width: 992px){
        background-position: left !important;
        background-size: 100% !important;
        background-image: url('../src/img/meet-us-mobile.png');
        min-height: 400px;
    }

    @media(max-width: 600px){
        min-height: 300px;
    }

    &_triangle{
        position: absolute;
        top: 0;
        right: 0;
        border-top: 600px solid #f8f8f8;
        border-left: 1000px solid transparent;

        @media(max-width: 992px){
            border-top: 400px solid #f8f8f8;
            border-left: 680px solid transparent;
        }

        @media(max-width: 600px){
            border-top: 230px solid #f8f8f8;
            border-left: 370px solid transparent;
        }
    }

    &_content{
        font-size: 36px;
        color: #696969;
        text-align: right;
        position: absolute;
        top: 10px;
        right: 50px;
        
        &.light{
            font-weight: 300;
        }

        @media(max-width: 992px){
            font-size: 22px;
            top: 15px;
            right: 30px;
        }

        @media(max-width: 600px){
            font-size: 15px;
            top: 30px;
            right: 20px;
        }
    }
}

.about-company{
    position: relative;
    overflow: hidden;

    &:after{
        content:'';
        position: absolute;
        top: 50px;
        left: 40%;
        background:url('../src/img/about-company-bar.png')no-repeat;
        width: 903px;
        height: 829px;
        pointer-events: none;

        @media(max-width: 1400px){
            left: 38%;
        }

        @media(max-width: 993px){
            display: none;
        }
    }
}

.other-opinions{
   
    &_single{
        padding: 0 30px;

        .img-box{
            min-height: 70px;
        }
    }

    &_content{
        font-weight: 300;
        font-style: italic;
        font-size: 18px;
        color: #696969;
        position: relative;

        &:before{
            content:'';
            position: absolute;
            top: -30px;
            left: 0;
            background:url('../src/img/about-company-icon.png')no-repeat;
            width: 67px;
            height: 56px;
        }
    }
}

.other-services{
    background: #f8f8f8;
    padding: 50px 0 50px 0;
    position: relative;   

    @media(max-width: 992px){
        padding: 0px 0 50px 0;
    }

    &:after{
        content:'';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 0;
        border-bottom: 400px solid #fff;
        border-right: 250px solid transparent;

        @media(max-width: 1025px){
            display: none;
        }
    }

    &_title{
        font-weight: 200;
        color: #696969;
        font-size: 40px;

        @media(max-width: 768px){
            font-size: 28px;
        }

        span{
            font-size: 34px;

            @media(max-width: 768px){
                font-size: 22px;
            }
        }
    }

    &_img-box{
       min-height: 380px; 
    }

    &_logo-box{
        min-height: 85px;
    }
    
}

.contact-section{
    background: #fff;
    padding: 100px 0 100px 0;
    position: relative;   

    @media(max-width: 992px){
        padding: 50px 0 50px 0;
    }

    &_box p, .img-box{
        @media(max-width: 992px){
            width: 200px;
            text-align: center;
        }
    }

    p{
        font-size: 14px;
        font-weight: 300;
        color: #696969;
        margin: 0;
        padding: 0;

        @media(max-width: 992px){
            margin-bottom: 10px;
        }
    }

    .logo-footer{
        position: relative;

        &:after{
            content: '';
            width: 2px;
            height: 50px;
            background: #000;
            top: 50%;
            right: 0;
            position: absolute;
            transform: translateY(-50%);

            @media(max-width: 992px){
                display: none;
            }
        }
    }

    
}


.cookies{
    position: fixed;
    background: #f8f8f8;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 100px;
    z-index: 99999;
    text-align: justify;
    color: #696969;
    transform: translateY(200%);
    transition: 1s transform;

    @media(max-width: 768px){
        padding: 20px;
        font-size: 13px;
    }
}

.goIn{
    transform: translateY(0px);
    transition: 1s transform;
}
