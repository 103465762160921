$mobile-breakpoint : 992px;
$hamburger-size: 40px;

.nav-hamburger{
    display: none;
    cursor: pointer;
    width: $hamburger-size;

    @media(max-width: $mobile-breakpoint){
        display: block;
        z-index: 99;
    }

    span{
        display: block;
        height: 2px;
        position: relative;
        width: 100%;
    
        &:after{
            content: '';
            height: 2px;
            background: #000;
            display: block;
            position: absolute;
            right: 0;
            transition: all .3s ease; 
        }
    }
        
    span:nth-child(2){
        margin: 9px 0; 
    }

    span:nth-child(1):after{
        width: $hamburger-size - 5px; 
    }

    span:nth-child(2):after{
        width: $hamburger-size; 
    }

    span:nth-child(3):after{
        width: $hamburger-size - 15px;
    }
    
    &:hover{
        span:after{
            width: $hamburger-size; 
        }
    }
}

@keyframes ghost{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }   
}

#brief{
    display: none !important;
    @media(max-width: 992px){
        display: inline-block !important;
    }
}

body.menu-open{
    
    .menu-container{
        transform: translateX(0) !important;
        // transition: .2s transform;
        animation: ghost .2s forwards;
    }

    .courtine{
        display: block;
    }

    .nav-hamburger{
        span:after{
            width: 35px;
            background: #000; 
        }

        span:nth-child(1):after {
            transform: translateY(11px) rotateZ(45deg); 
        }

        span:nth-child(2):after {
            width: 0;
            transform: translateX( - (35px / 2) ); 
        }

        span:nth-child(3):after {
            transform: translateY(-11px) rotateZ(-45deg); 
        }
    }

    .nav_menu{

        li{
            color: #000 !important;
        }

        li:first-child{
            transform: translateX(0);
            transition: .2s all;
        }

        li:nth-child(2){
            transform: translateX(0);
            transition: .4s all;
        }

        li:nth-child(3){
            transform: translateX(0);
            transition: .6s all;
        }

        li:nth-child(4){
            transform: translateX(0);
            transition: .8s all;
        }

        li:nth-child(5){
            transform: translateX(0);
            transition: 1s all;
        }
    }

}

