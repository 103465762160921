.btn-gradient{
    font-weight: 300;
    font-size: 20px;
    color: #fff;
    padding: 10px 40px;
    border-radius: 25px;   
    background: linear-gradient(to right, rgba(243,142,26,1) 0%, rgba(86,37,106,1) 100%);

    &:hover{
        text-decoration: none;
        color: #fff;
    }
}

.white-shadow-btn{
    outline: none;
    border: none;
    background: #fff;
    padding: 8px 50px;
    border-radius: 25px;
    font-weight: 300;
    color: #848484 !important;
    font-size: 22px;
    box-shadow: 2px 5px 29px -12px rgba(0,0,0,0.75);
    cursor: pointer;

    &:focus{
        outline: none;
    }
    
    &:hover{
        text-decoration: none;
        color: #848484;
    }
        
}

.unshadow{
    box-shadow: 2px 2px 20px -12px rgba(0,0,0,0.75);
}